@import '../../node_modules/normalize-scss/sass/normalize';

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
}

a {
  color: inherit;
  text-decoration: inherit;
  background-color: transparent;
}

a:-webkit-any-link {
  cursor: pointer;
}
