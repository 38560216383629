body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.container {
    position: relative;
    width: 100%;
    height: 100vh; 
    /* background: url('../images/atf-lifescore-intro.jpg') no-repeat center center/cover; */
    background: url('../../assets/images/cover.jpeg') no-repeat top center/cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1); /* dark overlay for text visibility */
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    flex: 0 0 66.666667%;
    max-width: 64.666667%;
    text-align: center;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
}

.title {
    font-family: "Brush Script MT", cursive;
    font-weight: 500;
    font-size: 65px;
    letter-spacing: 0.01em;
    line-height: 1.2;
    color: #d2922b;
    text-transform: none;
    margin-bottom: 35px;
}

.description {
    font-family: europa, sans-serif;
    font-weight: normal;
    font-size: 21px;
    letter-spacing: 0.05em;
    line-height: 33px;
    margin-bottom: 2rem;
}

.cta-button {
    background-color: #d2922b;
    color: #080707;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 50%;
}
.cta-button:hover {
    background-color: #131415;
    color: #f5f5f5;
    -webkit-box-shadow: 0px 5px 10px 0px #4b51947c;
    -moz-box-shadow: 0px 5px 10px 0px #4b51947c;
    box-shadow: 0px 5px 10px 0px #4b51947c;
}

.start-quiz-button {
    background-color: #4b5194; 
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.start-quiz-button:hover {
    background-color: #4b51947c;
    -webkit-box-shadow: 0px 5px 10px 0px #4b51947c;
    -moz-box-shadow: 0px 5px 10px 0px #4b51947c;
    box-shadow: 0px 5px 10px 0px #4b51947c;
}

/* How It Works Section */
.how-it-works {
    background: #fff;
    color: #000;
    padding: 50px 20px;
    text-align: center;
}

.how-it-works-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.assessment-image {
    width: 50%;
    max-width: 500px;
    margin-right: 40px;
}

.how-it-works-text {
    max-width: 600px;
    text-align: left;
}

.how-it-works-text h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.how-it-works-text p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.how-it-works-text .cta-button {
    background-color: #C3A37D; 
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.how-it-works-text .cta-button:hover {
    background-color: #B58A60; 
}

/* Footer */
footer {
    background: #333;
    color: #fff;
    padding: 20px 0;
    text-align: center;
}

.footer-content {
    background: #444;
    padding: 10px 0;
}

.footer-content a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
}

.footer-bottom {
    background: #333;
    padding: 10px 0;
    margin-top: 10px;
}

.footer-bottom p {
    margin: 0;
}

.footer-bottom a {
    color: #C3A37D;
    text-decoration: none;
}

@media (max-width: 1200px) {
    .title {
        font-size: 70px;
    }

    .description {
        font-size: 18px;
    }

    .how-it-works-text h2 {
        font-size: 2em;
    }

    .how-it-works-text p {
        font-size: 1em;
    }
}

@media (max-width: 992px) {
    .title {
        font-size: 50px;
    }

    .description {
        font-size: 16px;
    }

    .how-it-works-content {
        flex-direction: column;
    }

    .assessment-image {
        width: 80%;
        margin: 0 auto 20px;
    }

    .how-it-works-text {
        text-align: center;
    }

    .how-it-works-text h2 {
        font-size: 1.8em;
    }

    .how-it-works-text p {
        font-size: 0.9em;
    }
}

@media (max-width: 768px) {
    .title {
        font-size: 40px;
    }

    .description {
        font-size: 14px;
    }

    .how-it-works {
        padding: 30px 10px;
    }

    .how-it-works-text h2 {
        font-size: 1.5em;
    }

    .how-it-works-text p {
        font-size: 0.8em;
    }
}

@media (max-width: 576px) {
    .container {
        /* height: 120vh; */
        margin-bottom: 50px;
    }
    .title {
        font-size: 30px;
    }

    .overlay {
        align-items: unset;
    }

    .content {
        flex: 0.8;
        max-width: none;
    }
    .description {
        font-size: 12px;
        line-height: 1.2;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .how-it-works-text h2 {
        font-size: 1.2em;
    }

    .how-it-works-text p {
        font-size: 0.7em;
    }

    .cta-button {
        padding: 8px 16px;
        font-size: 0.8em;
        width: 100%;
    }
}

