@import '../../styles/utilities.scss';

.back__link {
  position: fixed;
  right: 2rem;
  top: 2rem;

  @include bigPhoneAndUp {
    right: 1rem;
    top: 1rem;
  }

  padding: 0.3rem;
  display: inline-flex;
  align-items: center;
  border: 0px;
  background: transparent;
  font-size: 1.875rem;
  font-family: $parFont;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.8);
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }

  &:focus {
    outline: 0;
  }
}

.quiz__result-message {
  font-family: $titleFont;
  margin: 10rem 1.5rem 0 1.5rem;
  line-height: 2.5rem;
  font-size: 1.5rem;
  font-weight: 500;

  a {
    font-style: italic;
    text-decoration: underline;
    color: #1b1bb4;
  }

  button {
    width: 200px;
    height: 54px;
    font-family: $titleFont;
    background-color: $buttonColor;
    // border-radius: 49.5px;
    color: white;
    letter-spacing: 2.4px;
    font-size: 12px;
    font-weight: bold;
    transition: 1s;

    &:hover {
      -webkit-box-shadow: 0px 20px 30px 0px $buttonColor;
      -moz-box-shadow: 0px 20px 30px 0px $buttonColor;
      box-shadow: 0px 20px 30px 0px $buttonColor;
    }
  }

  @include bigPhoneAndUp {
    margin: 1rem;
    font-size: 2rem;
    line-height: 3.5rem;
  }
}

// body {
//   font-family: Arial, sans-serif;
//   background-color: #f4f4f4;
//   padding: 20px;
// }

.contact-form {
  max-width: 70%;
  width: 50%;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 8px;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 10px;
  // background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: $titleFont;
  background-color: $buttonColor;
  // border-radius: 49.5px;
  color: white;
  letter-spacing: 2.4px;
  font-size: 12px;
  font-weight: bold;
  transition: 1s;

  &:hover {
    -webkit-box-shadow: 0px 5px 10px 0px $buttonColor;
    -moz-box-shadow: 0px 5px 10px 0px $buttonColor;
    box-shadow: 0px 5px 10px 0px $buttonColor;
  }
}

// input[type="submit"]:hover {
//   background-color: #0056b3;
// }