@import '../../styles/utilities.scss';

.choice-option__wrapper {
  justify-self: center;
  align-self: center;
  flex-grow: 1;
  position: relative;
  cursor: pointer;
  // margin: 0 2rem 0 2rem;
  margin: 2rem;
}
