@import '../../styles/utilities.scss';

.choice-option {
  &.selected::before {
    content: '';
    position: absolute;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background: url(../../assets/svg/choice-icon.svg) no-repeat center
      $buttonColor;
  }

  &.radioOption::before {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 1px solid;
    width: 28px;
    height: 28px;
    padding: 2px;
    // background: url(../../assets/svg/choice-icon.svg) no-repeat center
    //   $buttonColor;
  }

  &.option-img img {
    margin: 0 auto 0 auto;
    display: block;
  }

  &.option-text {
    display: block;
    // font-size: 2.25rem;
    font-size: 1.6rem;
    margin: 0 auto 0 auto;
    text-align: left;

    & > span {
      margin-left: 2.5rem;
    }

    // &.selected::before {
    //   top: 1rem;
    // }
  }
}
