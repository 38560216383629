@import '../../styles/utilities.scss';

.question__section {
  display: flex;
  flex-direction: column;

  .question__title {
    text-align: center;
    margin: 1.5rem;
    margin-bottom: 3rem;
    font-weight: 700;
    font-size: 2rem;
    font-family: $titleFont;
    letter-spacing: 0px;
    line-height: 3.5rem;
  }

  .question__list {
    margin-top: 0.5rem;
    flex-wrap: wrap;
    // display: flex;
  }
}
